import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import logo from "../../../assets/images/logo.png"
import "./Header.scss";
import {FormattedMessage} from 'react-intl';
import SmoothScroll from "../../../../utils/Helpers/SmoothScroll"
// import { MAP_TABS } from '../../../../constants/mapTabs';

const Header = ({ page, pageid, language, changeLanguage }) => {
  const [collapse, setCollapse] = useState(false);
  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;
  // const languageOptions = () => {
  //   return (
  //     <>
  //       <option
  //         value={'ES'}
  //         key={'ES'}
  //       >
  //         {'Spanish'}
  //       </option>
  //     </>
  //   )
  // }

  const onChangeLanguage = value => {
    changeLanguage(value)
    SmoothScroll("root")
  }
  

  return (
    <div className="Header" id={page}>
      <div className="container" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div>
            <a
              href="https://www.anaheim.net/226/Public-Utilities"
              // target="_blank"
              // rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid"
              />
            </a>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="" navbar>

              <NavItem>
                  <Link to="/" className={isHomepage ? "active" : ""}>
                    <span>
                      <FormattedMessage
                      id="evhome"
                      defaultMessage="EV Home"
                      description="EV Home Header"
                    />
                    </span>
                  </Link>
              </NavItem>


            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={page === "vehicles" ? "Active Page" : null}
                >
                  <span>
                    <FormattedMessage
                        id="electricVehicles"
                        defaultMessage="Electric Vehicles"
                        description="Electric Vehicles Header"
                      />
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem
                      key="nav-item-1"
                      role="menuitem"
                      tag={NavLink}
                      title={page === "vehicles" ? "Active Page" : null}
                      to="/vehicles"
                    >
                      <span><FormattedMessage
                        id="electricVehiclesHeader"
                        defaultMessage="Electric Vehicles"
                        description="Electric Vehicles Header"
                      /></span>
                    </DropdownItem>
                    <DropdownItem
                      key="nav-item-2"
                      role="menuitem"
                      tag={NavLink}
                      title={page === "used-vehicles" ? "Active Page" : null}
                      to="/used-vehicles"
                    >
                      <span>
                        <FormattedMessage
                        id="usedElectricVehiclesHeader"
                        defaultMessage="Used Vehicles"
                        description="Used Vehicles Header"
                      /></span>
                    </DropdownItem>
                    <DropdownItem
                      key="nav-item-3"
                      role="menuitem"
                      tag={NavLink}
                      title={page === "compare-vehicles" ? "Active Page" : null}
                      to="/compare-vehicles"
                    >
                      <span>
                      <FormattedMessage
                        id="compareVehiclesHeader"
                        defaultMessage="Compare Vehicles"
                        description="Compare Vehicles Header"
                      />
                      </span>
                    </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>


              { process.env.REACT_APP_PAGES_INCENTIVES_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/incentives"
                  >
                    <span>
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}

                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/dealers"
                  >
                    <span>
                      <FormattedMessage
                        id="dealers"
                        defaultMessage="Dealers"
                        description="Dealers Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    title={page === "charging-stations" ? "Active Page" : null}
                    to="/charging-stations"
                  >
                    <span>
                      <FormattedMessage
                        id="chargingStations"
                        defaultMessage="Charging Stations"
                        description="Charging Stations Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    title={page === "home-chargers" ? "Active Page" : null}
                    to="/home-chargers"
                  >
                    <span>
                      <FormattedMessage
                        id="homeChargers"
                        defaultMessage="Home Chargers"
                        description="Home Chargers Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>

            </Nav>
          </Collapse>
        </Navbar>
        <div className="language-toggle">
        {process.env.REACT_APP_LANGUAGE_TOGGLE ?
            <ul>
              <li>
                <button className={language === "EN" ? "btn-lang active" : "btn-lang"} onClick={() => onChangeLanguage('EN')}>English</button>
              </li>
              <li>
                <button className={language === "ES" ? "btn-lang active" : "btn-lang"} onClick={() => onChangeLanguage('ES')}>Español</button>
              </li>
            </ul>
            : null}
        </div>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};
