import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import Uuid from "../../../utils/Uuid/Uuid"


import ChargerCatalog from "../../../components/ChargerCatalog/ChargerCatalog"
import ChargerSortControls from "./../../../components/ChargerSortControls/ChargerSortControls";
import HomeChargersFilterControls from "./../../../components/HomeChargersFilterControls/HomeChargersFilterControls";
import ModalComponent from "../../../components/ModalUIComponent/ModalUIComponent";
import { FormattedMessage} from 'react-intl'

import "./HomeChargers.scss"

const HomeChargers = ({ electricVehicles, homeChargers, ip, uuid }) => {
    useEffect(() => {
        document.title = process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE;
    });
    // const intl = useIntl();
    // const [makeOption, setMakeOption] = useState('')
    // const [modelOption, setModelOption] = useState('')
    const [ev, setEv] = useState(null)


    if (!electricVehicles) {
        return null;
    }

    // const onChangeMake = e => {
    //     const newMakeType = e.target.value

    //     setMakeOption(newMakeType)
    // }

    // const onChangeModelType = e => {
    //     const newModelType = e.target.value
    //     const vehicleHandle = e.target.options[e.target.selectedIndex].dataset.handle

    //     if (newModelType === 'Select a Model') {
    //         setModelOption(newModelType)
    //         return
    //     }

    //     const selectedVehicle = electricVehicles.find(vehicle => {
    //         return vehicle.handle === vehicleHandle
    //     })

    //     setModelOption(newModelType)
    //     setEv(selectedVehicle)
    // }

    const time = new Date().toLocaleString();

    const renderOptions = (
        <>
            <HomeChargersFilterControls electricVehicles={electricVehicles} ev={ev} setEv={setEv} />
        </>
    );

    return (
        <div className="home-chargers">
            <section className="container">
                <div className="row mb-3">
                    <div className="col-sm-12 text-center title-area">
                        <h2><FormattedMessage
                            id="homeChargersTitle"
                            defaultMessage="Select a Home Charger"
                            description="Home Charger Title"
                        /></h2>
                        <p><FormattedMessage
                            id="homeChargersDescription"
                            defaultMessage="Find the best EV home charger for your car based on price, type and compatibility. Plus, you may be eligible for the Personal Use Electric Charging Station Rebate of up to $500."
                            description="Home Charger Description"
                            values={{
                                link:
                                    <a
                                        href="http://www.anaheim.net/593/Personal-EV-Charger-Rebate"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FormattedMessage
                                            id="homeChargersLink"
                                            defaultMessage="Find out here."
                                            description="Home Charger Link"
                                        />
                                    </a>
                            }}
                        /></p>
                    </div>
                </div>

                {/* <div className="row" style={{justifyContent: "center"}}>
          <div className="col-sm-3">
            <div className="form-group">
              <select
                className="form-control select-iid"
                value={makeOption}
                onChange={onChangeMake}
              >
                <option defaultValue="">
                  {intl.formatMessage({id: "homeChargers.selectMake", defaultMessage: "Select a Make" })}
                </option>
                {vehicleMakeOptions(electricVehicles)}
              </select>
            </div>
          </div>
            
        <div className="col-sm-3">
            <div className="form-group">
              <select
                className="form-control select-iid"
                value={modelOption}
                onChange={onChangeModelType}
              >
                
                <option defaultValue="">
                  {intl.formatMessage({ id: "homeChargers.selectModel", defaultMessage: "Select a Model" })}
                </option>
                {vehicleModelOptions(electricVehicles, makeOption)}
              </select>
            </div>
          </div>
        </div>

        <div className="row" style={{justifyContent: "center"}}>
          <p className="lead text-center">
            <FormattedMessage
              id="homeChargers.subText"
              defaultMessage="Select the vehicle you are interested in to see the specific charging time to a full battery."
              description="Select a Model/Make subtext"
            />
          </p>
        </div> */}

                <div className="row">
                    <div className="col-sm-12 d-block d-lg-none text-center">
                        <ModalComponent
                            buttonText="Filters"
                            titleText="Refine Filters"
                        >
                            {renderOptions}
                        </ModalComponent>
                        <br />
                    </div>
                    <div className="col-xl-3 col-lg-4 d-none d-lg-block">
                        {renderOptions}
                    </div>
                    <div
                        id="home-chargers-catalog"
                        aria-live="polite"
                        aria-atomic="true"
                        role="region"
                        style={{ position: "absolute", top: "-9999px" }}
                    >
                        <span>
                            <FormattedMessage
                                id="results.updated"
                                defaultMessage="Results have been updated as of "
                                description="Results have been updated as of "
                            />{time}.
                        </span>
                    </div>
                    <div
                        className="col-xl-9 col-lg-8 col-sm-12"
                        style={{ paddingLeft: 10 }}
                    >
                        <div className="row">
                            <div className="col">
                                <div className="pull-right">
                                    <ChargerSortControls />
                                </div>
                            </div>
                        </div>
                        <ChargerCatalog
                            homeChargers={homeChargers} selectedVehicle={ev}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomeChargers;

HomeChargers.propTypes = {
    electricVehicles: PropTypes.array,
    homechargers: PropTypes.array,
    ip: PropTypes.string,
    uuid: PropTypes.string
};