import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.scss";
import {FormattedMessage} from 'react-intl';
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
// import { MAP_TABS } from '../../../../constants/mapTabs';

const Footer = ({language, page, pageid, changeLanguage}) => {

  return (
    <div className="Footer" id={page}>
      <div className="arrow-down"></div>
      <div className="container">
        <ul className="links-top">
          <li>
            <span>Anaheim Public Utilities</span>
          </li>
          <li>
            <span>201 South Anaheim Blvd</span>
          </li>
          <li>
            <span>Anaheim, CA 92805</span>
          </li>
          <li>
            <span>Phone: 714-765-3300</span>
          </li>
          <li>
            <span>Dial 311</span>
          </li>
          <li>
            <span>Emergency: Dial 911</span>
          </li>
        </ul>
        <ul className="links-bottom">
          <li>
          <NavLink
              title={page === "vehicles" ? "Active Page" : null}
              to="/vehicles"
            >
                <FormattedMessage
                  id="electricVehiclesHeader"
                  defaultMessage="Electric Vehicles"
                  description="Electric Vehicles Header"
                />
              </NavLink>
          </li>
          <li>
            <NavLink
              title={page === "used-vehicles" ? "Active Page" : null}
              to="/used-vehicles"
            >
              <FormattedMessage
                  id="usedElectricVehiclesHeader"
                  defaultMessage="Used Vehicles"
                  description="Used Vehicles Header"
                />
              </NavLink>
          </li>
          <li>
            <NavLink
              title={page === "compare-vehicles" ? "Active Page" : null}
              to="/compare-vehicles"
            >
              <FormattedMessage
                  id="compareVehiclesHeader"
                  defaultMessage="Compare Vehicles"
                  description="Compare Vehicles Header"
                />
            </NavLink>
          </li>
          <li>
            <NavLink
              title={page === "incentives" ? "Active Page" : null}
              to="/incentives"
            >
              <FormattedMessage
                id="incentives"
                defaultMessage="Incentives"
                description="Incentives Header"
              />
            </NavLink>
          </li>
          <li>
          <NavLink
              title={page === "charging-stations" ? "Active Page" : null}
              to="/charging-stations"
            >
              <FormattedMessage
                id="chargingStations"
                defaultMessage="Charging Stations"
                description="Charging Stations Header"
              />
            </NavLink>
          </li>
          <li>
          <NavLink
              title={page === "home-chargers" ? "Active Page" : null}
              to="/home-chargers"
            >
              <FormattedMessage
                id="homeChargers"
                defaultMessage="Home Chargers"
                description="Home Chargers Header"
              />
            </NavLink>
          </li>
        </ul>
        <p className="copyright"  style={{marginTop:"15px"}}>
          <span style={{ fontFamily: "Arial, Helvetica",marginLeft:"-300px" }}>&copy;</span> {(new Date().getFullYear().toString()) } {process.env.REACT_APP_FULL_COMPANY_NAME}. All Rights Reserved. 
          {/* <span style={{ fontFamily: "Arial, Helvetica" }}> &copy;
            {(new Date().getFullYear().toString()) } ZappyRide (c). <a rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices">Legal notices.</a></span>  */}
        </p>
        <span className="disclaimer_container">
                  <DisclaimerComponent
                    clientName="zappynobackground"
                    textStyle={{
                      fontFamily: "Arial, Helvetica",
                      fontSize: "10.6667px",
                      textAlign: "center"
                    }}
                    imageStyle={{ marginLeft: "550px" , marginTop:"-20px"}}
                    wrapperStyle={{
                      padding: "0px",
                    }}
                  />
                </span>
      </div>
    </div>
  );
};

export default Footer;
