const ESP_CUSTOM = {
    "electricVehiclesHeader": "Autos Eléctricos",
    "usedElectricVehiclesHeader": "Autos Eléctricos Usados",
    "compareVehiclesHeader": "Compare Vehículos",
    "homeHeroTitle": "Tu guía de vehículos eléctricos.",
    "homeHeroSubText": "¡Su viaje hacia un futuro más sostenible comienza aquí!",
    "homeHeroSubTitle": "Compare costos, ahorros, incentivos y más.",
    "selectMake": "Seleccione una marca",
    "selectModel": "Seleccione un modelo",
    "homeChargersTitle": "Seleccione un cargador",
    "homeChargersDescription": "Encuentre el mejor cargador doméstico para vehículos eléctricos para su automóvil según el precio, el tipo y la compatibilidad. Además, puede ser elegible para el reembolso de la estación de carga eléctrica para uso personal de hasta $ 500. {link}",
    "homeChargersLink": "Descúbrelo aquí.",
    "compatibility": "Compatibilidad",
    "roundTripCommute": "Viaje de ida y vuelta",

    "electricVehiclesToggle": "Nuevo",
    "usedElectricVehiclesToggle": "Usado",
    "homepage.vehicleCarousel.description": "Compare el costo de su selección con un vehículo de gasolina similar. Un vehículo eléctrico puede ahorrarle dinero porque puede ser más económico de poseer y mantener que un automóvil 100% a gasolina.",
    "homepage.linkCard.utilityRates": "Explore las tarifas de servicios públicos",
    "evhome": "EV Home",
    "vehicle.milesCapital": "Millas",
    "ev.used.electricRange": "Promedio de rango eléctrico",
    "ev.used.yearRange": "Rango de año",

    "chargingStations.mapLegend.public": "Estaciones públicas",
    "chargingStations.mapLegend.publicSubtext": "Instalado por empresas o gobiernos",
    "chargingStations.mapLegend.high": "Estaciones de alta potencia",
    "chargingStations.mapLegend.highSubtext": "Carga rápida DC o sobrealimentadores",
    "chargingStations.mapLegend.other": "Otros tipos de estaciones",
    "chargingStations.mapLegend.private": "Estaciones privadas",
    "ft":"ft",
    "lbsperyear": "lbs/yr",
    "vehicle.gasoline.price": "Precio de gasolina ($/gal)",
    "ev.carDetails.mobile": "For more details including charts {amp} calculations, please view this page on a desktop.",
    "graph.electricRange.description": "Descripción",
    "graph.electricRange.electricity": "Fuente",
    "graph.electricRange.gasoline": "Gasolina",
    "graph.electricRange.total": "Total"
}


export default ESP_CUSTOM;