import React from "react";
import PropTypes from "prop-types";

import "./LinkCard.scss";

import BROWSE_VEHICLES from "../../client_customizations/assets/images/icons/browse-electric-vehicles.svg";
import DISCOVER_INCENTIVES from "../../client_customizations/assets/images/icons/discover-incentives.svg";
import LOCATE_CHARGING_STATIONS from "../../client_customizations/assets/images/icons/locate-charging-stations.svg";
// import EV_FACTS from "../../client_customizations/assets/images/icons/ev-facts.svg";
import FIND_DEALERS from "../../client_customizations/assets/images/icons/find-dealers.svg"
import HOME_CHARGERS from "../../client_customizations/assets/images/icons/home-chargers.svg"
import { Link } from "react-router-dom";
import SmoothScroll from "./../../utils/Helpers/SmoothScroll";
import {FormattedMessage} from 'react-intl';

const LinkCard = ({ type, isLink }) => {
  let src, link, scrollTo, title;

  switch (type) {
    case "BROWSE_VEHICLES":
      src = BROWSE_VEHICLES;
      scrollTo = "HomepageVehiclesCarousel";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.browseElectricVehicles"
            defaultMessage="BROWSE ELECTRIC {lineBreak} VEHICLES"
            description="HomePage Linkcard Browse Electric Vehicles"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "DISCOVER_INCENTIVES":
      src = DISCOVER_INCENTIVES;
      scrollTo = "HomepageIncentives";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.discoverIncentives"
            defaultMessage="DISCOVER {lineBreak} INCENTIVES"
            description="HomePage Linkcard Discover Incentives"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "LOCATE_CHARGING_STATIONS":
      src = LOCATE_CHARGING_STATIONS;
      // scrollTo = "HomepageChargingStations";
      link = "/charging-stations";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.locateChargingStations"
            defaultMessage="LOCATE CHARGING {lineBreak} STATIONS"
            description="HomePage Linkcard Locate Charging Stations"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "FIND_DEALERS":
      src = FIND_DEALERS;
      link = "/dealers";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.findDealers"
            defaultMessage="Find {lineBreak} dealers"
            description="Find Dealers Title"
            values={{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "HOME_CHARGERS":
      src = HOME_CHARGERS;
      link = "/home-chargers";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.homeChargers"
            defaultMessage="HOME {lineBreak} CHARGERS"
            description="HomePage Linkcard Home Chargers"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
        break;
      case "UTILITY_RATES":
      src = FIND_DEALERS;
      link = "http://anaheim.net/605/Load-Management-Techniques-Rates";
      title = (
        <span>
          <FormattedMessage
            id="homepage.linkCard.utilityRates"
            defaultMessage="Explore Utility {lineBreak} Rates"
            description="HomePage Linkcard Utility Rates"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    default:
      return null;
  }

  const displayLinkCard = (link, src, title, isLink) => {
    if(isLink) {
      return (
        <div className="link-card">
          <a href={link} className="evc-card" target="_blank" rel="noopener noreferrer">
            <div>
              <img src={src} alt="" />
              <p className="h6">{title}</p>
            </div>
          </a>
        </div>
      )
    } else {
      return (
        <div className="link-card">
        <Link to={link} className="evc-card">
          <div>
            <img src={src} alt="" />
            <p className="h6">{title}</p>
          </div>
        </Link>
      </div>
      )
    }
  }

  return scrollTo ? (
    <div className="link-card">
      <div className="evc-card" onClick={e => SmoothScroll(scrollTo)}>
        <div>
          <img src={src} alt="" />
          <p className="h6">{title}</p>
        </div>
      </div>
    </div>
  ) : (
    displayLinkCard(link, src, title, isLink)
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string
};
