import React from "react";
import PropTypes from "prop-types";
import LinkCard from   "../../../../components/LinkCard/LinkCard";
import {FormattedMessage} from 'react-intl';

const HomepageBigPromise = ({
  homePageBannerImage
}) => {
  return (
    <section className="container p-0">
      <div className="big-promise" style={{ position: "relative" }}>
        <h1>
        <FormattedMessage
            id="homeHeroTitle"
            defaultMessage="Drive Clean & Green with Anaheim"
            description="HomePage Hero Title"
          />
        </h1>
        <p><FormattedMessage
            id="homeHeroSubText"
            defaultMessage="Your journey to a more sustainable future starts here!"
            description="HomePage Hero Title Sub Text"
          /></p>
      </div>

      <div id="link-card-buttons">
        <h2>
          <FormattedMessage
            id="homeHeroSubTitle"
            defaultMessage="Compare costs, savings, incentives, and more."
            description="HomePage Hero Sub Title"
          />
        </h2>
        <div className="row">
          <div className="col">
            <div className="link-card-container">
              <LinkCard type="BROWSE_VEHICLES" isLink={false} />
              <LinkCard type="DISCOVER_INCENTIVES" isLink={false} />
              <LinkCard type="LOCATE_CHARGING_STATIONS" isLink={false}/>
              {/* <LinkCard type="EV_FACTS" /> */}
              {/* <LinkCard type="FIND_DEALERS" /> */}
              <LinkCard type="UTILITY_RATES" isLink={true} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string
}