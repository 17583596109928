import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TabbedMaps from '../../../components/TabbedMaps/TabbedMaps';
import useMapTabs from '../../../hooks/useMapTabs';
import { FormattedMessage } from 'react-intl';

import "./MapPage.scss";

const MapPage = ({ userLocation, ip, uuid, history, dealerLocations, tabId, title, zipcode }) => {
    useEffect(() => {
        document.title = title;
    });

    const { activeTabId, toggle, findTab } = useMapTabs(tabId);

    const toggleWithHistory = newTabId => {
        const newTab = findTab(newTabId);
        history.push(newTab.url);
        toggle(newTab.id);
    };

    const headerTitle = tabId => {
        if (tabId === "tabbed-maps-all-stations-tab") {
            return (
                <FormattedMessage
                    id="chargingStations"
                    defaultMessage="Charging Stations"
                    description="Charging Stations Header"
                />
            )
        } else if (tabId === "tabbed-maps-dealer-catalog-tab") {
            return (
                <FormattedMessage
                    id="dealers"
                    defaultMessage="Dealers"
                    description="Dealers Header"
                />
            )
        }
    }

    return (
        <section className="container">
            <div className="MapPage">
                <h1>{headerTitle(tabId)}</h1>

                <TabbedMaps
                    toggle={toggleWithHistory}
                    activeTabId={activeTabId}
                    userLocation={userLocation}
                    dealerLocations={dealerLocations}
                    zipcode={zipcode}
                />
            </div>

        </section>
    );
};

export default MapPage;

MapPage.propTypes = {
    userLocation: PropTypes.object,
    ip: PropTypes.string,
    uuid: PropTypes.string
};