// Regression Coefficients are calculated https://docs.google.com/spreadsheets/d/1d2dYl_JoBgdICGurjc2MaJylAU41hlk2yjbx0wuhygY/edit#gid=1241204193 

const electricBFactor = {BMW: .233, Audi: .176, Nissan: .221, Ford: .198, Chevrolet: .197, Fiat: .4, Tesla: .123, Toyota: .114, Average: .201}
const gasBFactor = {BMW: .168, Audi: .174, Nissan: .18, Ford: .165, Chevrolet: .164, Fiat: .157, Toyota: .126, Average: .158}

// Battery Degredation is Year: Percent Lost
const batteryDegredation = {0: 1, 1: .98, 2: .96, 3: .939, 4: .919, 5: .899, 6: .879, 7: .859}

const ageCalculator = modelYear => {
  return Math.max(0, new Date().getFullYear() - modelYear) > 7
  ? 7
  : Math.max(0, new Date().getFullYear() - modelYear);
}

const msrpCalculator = (vehicle, fuel) => {
    const age = ageCalculator(vehicle.model_year)
    const bFactor = fuel === "gas" ? gasBFactor : electricBFactor
    let finalCoeff = bFactor[vehicle.make] ? bFactor[vehicle.make] : bFactor.Average
    let msrp = vehicle.msrp * Math.pow(Math.E, -1 * age * finalCoeff)
    msrp = Math.round(msrp / 1000) * 1000
    const lowerBoundMsrp = Math.round(msrp * .7 / 100) * 100
    const upperBoundMsrp = Math.round(msrp * 1.3 / 100) * 100
    return {
      msrp: msrp,
      lowerBoundMsrp:lowerBoundMsrp,
      upperBoundMsrp : upperBoundMsrp
    }
  }

const UsedVehicle = (vehicle) => {
    let electricMsrps = msrpCalculator(vehicle, "electric")
    let gasMsrps = msrpCalculator(vehicle.equivalent_gas_vehicle, "gas")
    vehicle.msrp = electricMsrps.msrp
    vehicle["lowerBoundMsrp"] = electricMsrps.lowerBoundMsrp
    vehicle["upperBoundMsrp"] = electricMsrps.upperBoundMsrp
    vehicle.equivalent_gas_vehicle.msrp = gasMsrps.msrp
    vehicle.equivalent_gas_vehicle["lowerBoundMsrp"] = gasMsrps.lowerBoundMsrp
    vehicle.equivalent_gas_vehicle["upperBoundMsrp"] = gasMsrps.upperBoundMsrp
    vehicle.battery_capacity = Math.round(batteryDegredation[ageCalculator(vehicle.model_year)] * vehicle.battery_capacity)
    vehicle.electric_range = Math.round(batteryDegredation[ageCalculator(vehicle.model_year)] * vehicle.electric_range)

    return vehicle
};

export default UsedVehicle;
