import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { useIntl } from 'react-intl';
import Range from "../../shared/InputElements/Range";

const SlideMinSeats = ({
  id = "min-seats-range",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "evs.minSeats", defaultMessage: "Minimum Seats"}) : "Minimum Seats"
  const description = val => {
    return `${val} ${intl.formatMessage ? intl.formatMessage({ id: "evs.seats", defaultMessage: "seats"}) : "seats"}`;
  };


  return (
    <Range
      id={id}
      value={userPrefs.get("minSeats")}
      label={label}
      rangeMin={2}
      rangeMax={7}
      rangeStep={1}
      description={description}
      ariaControls="electric-vehicles-catalog"
      handler={e => userPrefs.set({ minSeats: e.target.value })}
      {...rest}
    />
  );
};

export default SlideMinSeats;

SlideMinSeats.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
