const EN_CUSTOM = {

    "ev.used.electricRange": "Electric Range Average",
    "ev.used.yearRange": "Year Range",
    "results.updated": "Results have been updated as of ",
    "loading": "Loading",

    "chargingStations.mapLegend.public": "Public Stations",
    "chargingStations.mapLegend.publicSubtext": "Installed by business or government",
    "chargingStations.mapLegend.high": "High Power Stations",
    "chargingStations.mapLegend.highSubtext": "DC fast charge or superchargers",
    "chargingStations.mapLegend.other": "Other Types of Stations",
    "chargingStations.mapLegend.private": "Private stations",
    "ft": "ft",
    "lbsperyear":"lbs/yr",
    "vehicle.gasoline.price": "Gasoline Price ($/gal)",
    "ev.carDetails.mobile": "For more details including charts {amp} calculations, please view this page on a desktop.",
    "graph.electricRange.description": "Description",
    "graph.electricRange.electricity": "Electricity",
    "graph.electricRange.gasoline": "Gasoline",
    "graph.electricRange.total": "Total",
    "ev.electricityRateCalc": "Electricity rate is calculated as a blended rate based off an average household consumption of 700 kWh. To learn more, please click {website}"
}

export default EN_CUSTOM;