import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CarGallery from "./CarGallery/CarGallery";
import CarDetails from "./CarDetails/CarDetails";
import CarDetailsCalcs from "./CarDetailsCalcs/CarDetailsCalcs";

import VehicleImage from "./../VehicleImage/VehicleImage";
import PricePanels from "./PricePanels/PricePanels";
import { FormatCarName } from "./../../utils/Helpers/Format";
import ShowHideButton from "../ShowHideButton/ShowHideButton";
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import UsedVehiclePanels from "./UsedVehiclePanels/UsedVehiclePanels";

import "./EVJumbotron.scss";

const EVJumbotron = ({ car, paymentDetails, cars, setUsedEv }) => {
  const intl = useIntl()
  if (!car) return null;

  //Error Checking code to ensure that pictures have both a thumbnail and full version sutible for display
  // It will remove any null value 
  let imagesSrc = car && car["images"] ? car.images.map((n) =>
    (n.url_thumbnail == null) ? [] :
      (n.url_full == null) ? [] :
        [n]) : [];

//Flatten evImgs into a single array                  
const images = [].concat.apply([], imagesSrc);
let carImages = images.map(image => {
  return {
    original: image.url_full,
    thumbnail: image.url_thumbnail,
    originalAlt: image["legal_info"],
    thumbnailAlt: image.title
  };
});
carImages.shift();

  return (
    <>
      <section id="ev-jumbotron-title">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>
                {setUsedEv ? 
                <FormattedMessage 
                  id="ev.usedCarTitle"
                  defaultMessage="Used {car}"
                  description="Used Car Title"
                  values= {{
                    car: FormatCarName(car)
                  }}
                />
                :
                FormatCarName(car)
                }
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-8">
              <div className="text-center offset-lg-1 col-lg-10 col-md-12">
                <VehicleImage image={images[0]} size="full" alt={FormatCarName(car)} />
              </div>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-4">
              <PricePanels car={car} paymentDetails={paymentDetails}  />
              <UsedVehiclePanels cars={cars} car={car} setUsedEv={setUsedEv}/>
              <br />
              {setUsedEv ? null : 
                <>
                  <div className="ev-pricepanel-buttons">
                      <a href={car.official_website_url}>
                        <button style={{whiteSpace: "nowrap"}}className="btn-ae">
                          <FormattedMessage 
                            id="ev.jumbotron.learnMore"
                            defaultMessage="Learn More"
                            description="Learn More"
                          />
                          </button>
                        </a>
                      <Link to="/vehicles">
                        <button style={{whiteSpace: "nowrap"}}className="btn-ae">
                          <FormattedMessage 
                            id="ev.jumbotron.viewAllCars"
                            defaultMessage="View All Cars"
                            description="View All Cars"
                          />
                        </button>
                      </Link>
                  </div>
                </>
              }
                {/* {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? 
                <div className="col">
                  <Link to="/compare-vehicles">
                    <button className="btn-ae">Compare</button>
                  </Link>
                </div>
                : null} */}
            </div>
          </div>
          </div>
        </section>
        <section className="container" id="ev-jumbotron-details">
          <div className="row">
            <div className="col-md-7">
              <CarDetails car={car} setUsedEv={setUsedEv}/>
              <br />
              <div className="text-center">
                <ShowHideButton
                  buttonText={intl.formatMessage({ id: "detailedCalculations", defaultMessage: "DETAILED CALCULATIONS"})}
                  displayAlertOnlyOnMobile
                >
                  <div className="input-well">
                    <CarDetailsCalcs car={car} />
                  </div>
                </ShowHideButton>
              </div>
            </div>
            <div className="col-md-5">
              <CarGallery carImages={carImages} />
            </div>
          </div>
        <br />
      </section>
    </>
  );
};

export default EVJumbotron;

EVJumbotron.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object
};
